import { useState, useEffect } from "react";
import { DateTime } from "luxon";

function App() {
  const [now, setNow] = useState(DateTime.now());
  const BabyBirthday = DateTime.fromISO("2021-06-08");
  const DaddyBirthday = DateTime.fromISO("1978-07-09");
  const MommyBirthday = DateTime.fromISO("1987-11-29");

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setNow(DateTime.now());
  }

  function getYearsMonthsDays(start) {
    const years = now.diff(start, ["years", "months", "days"]).years;
    const yearsPart = years
      ? `${years} ${years === 1 ? "year, " : "years, "}`
      : "";
    const monthsYear = Math.floor(
      now.diff(start, ["years", "months", "days"]).months
    );
    const monthsPart = monthsYear
      ? `${monthsYear} ${monthsYear === 1 ? "month" : "months"}`
      : "";
    const daysMonth = Math.floor(now.diff(start, ["months", "days"]).days);
    const daysPart = daysMonth
      ? ` and ${daysMonth} ${daysMonth === 1 ? "day" : "days"}`
      : "";
    return yearsPart + monthsPart + daysPart;
  }

  function getMonthsDays(start) {
    const months = now.diff(start, ["months", "days"]).months;
    const daysMonth = Math.floor(now.diff(start, ["months", "days"]).days);
    const monthsPart = `${months} ${months === 1 ? "month" : "months"}`;
    const daysPart = daysMonth
      ? ` and ${daysMonth} ${daysMonth === 1 ? "day" : "days"}`
      : "";
    return monthsPart + daysPart;
  }

  function getWeeksDays(start) {
    const weeks = now.diff(start, ["weeks", "days"]).weeks;
    const daysWeek = Math.floor(now.diff(start, ["weeks", "days"]).days);
    const weeksPart = `${weeks} ${weeks === 1 ? "week" : "weeks"}`;
    const daysPart = daysWeek
      ? ` and ${daysWeek} ${daysWeek === 1 ? "day" : "days"}`
      : "";
    return weeksPart + daysPart;
  }

  function getDays(start) {
    const days = Math.floor(now.diff(start, ["days"]).days);
    const daysPart = `${days} ${days === 1 ? "day" : "days"}`;
    return daysPart;
  }

  function getDay(start) {
    return Math.floor(now.diff(start, ["days"]).days) + 1;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 prose mx-auto">
        <div className="px-4 py-5 sm:px-6">
          {now.toFormat("DDDD")} {now.toFormat("TT")}
        </div>
        <div className="px-4 py-5 sm:p-6">
          Today is Baby's day n. {getDay(BabyBirthday)}
        </div>
        <div className="px-4 py-5 sm:p-6">
          Baby's age:
          <ul>
            {/* <li>{getYearsMonthsDays(BabyBirthday)} old</li> */}
            <li>{getMonthsDays(BabyBirthday)} old</li>
            <li>{getWeeksDays(BabyBirthday)} old</li>
            <li>{getDays(BabyBirthday)} old</li>
          </ul>
        </div>
        <div className="px-4 py-5 sm:p-6">
          Today is Mommy's day n. {getDay(MommyBirthday)}
        </div>
        <div className="px-4 py-5 sm:p-6">
          Mommy's age:
          <ul>
            <li>{getYearsMonthsDays(MommyBirthday)} old</li>
            <li>{getMonthsDays(MommyBirthday)} old</li>
            <li>{getWeeksDays(MommyBirthday)} old</li>
            <li>{getDays(MommyBirthday)} old</li>
          </ul>
        </div>
        <div className="px-4 py-5 sm:p-6">
          Today is Daddy's day n. {getDay(DaddyBirthday)}
        </div>
        <div className="px-4 py-5 sm:p-6">
          Daddy's age:
          <ul>
            <li>{getYearsMonthsDays(DaddyBirthday)} old</li>
            <li>{getMonthsDays(DaddyBirthday)} old</li>
            <li>{getWeeksDays(DaddyBirthday)} old</li>
            <li>{getDays(DaddyBirthday)} old</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
